export const postgresTimeToString = (postgresTime) => {
  const date = new Date(postgresTime);
  const timePart = date.toLocaleTimeString("en-gb");
  return `${date.toLocaleDateString()} ${timePart}`;
};

export const getFieldValueToExpose = ({ response, config }) => {
  if (typeof config.fieldNameToExpose !== "undefined") {
    return response[config.fieldIdToExpose];
  }
};

export const isOnAdminPage = () =>
  window.location.pathname.split("/")[1] === "admin";

export const currentPage = () => {
  if (window !== undefined) {
    return window.location.pathname;
  }
};

export const getTitle = (formType) => {
  if (formType === "complianceForms") {
    return "Compliance Forms";
  } else if (formType === "customerInformationForms") {
    return "Customer Information Forms";
  }
};

export const capitalizeFirstChar = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getTagBgColor = (status) => {
  const colors = {
    "in progress": "hsl(207, 61%, 80%)",
    "In progress": "hsl(207, 61%, 80%)",
    pending: "hsl(207, 61%, 80%)",
    Pending: "hsl(207, 61%, 80%)",
    submitted: "hsl(207, 61%, 53%)",
    Submitted: "hsl(207, 61%, 53%)",
    modified: "hsl(55, 65%, 70%)",
    Modified: "hsl(55, 65%, 70%)",
    "changes requested": "hsl(55, 80%, 85%)",
    "Changes requested": "hsl(55, 80%, 85%)",
    approved: "hsl(153, 53%, 53%)",
    Approved: "hsl(153, 53%, 53%)",
    active: "hsl(153, 53%, 53%)",
    Active: "hsl(153, 53%, 53%)",
    rejected: "hsl(0, 80%, 75%)",
    Rejected: "hsl(0, 80%, 75%)",
    closed: "hsl(0, 80%, 75%)",
    Closed: "hsl(0, 80%, 75%)",
    terminated: "hsl(0, 80%, 75%)",
    Terminated: "hsl(0, 80%, 75%)",
    trails: "hsl(153, 53%, 75%)",
    Trails: "hsl(153, 53%, 75%)",
    hold: "hsl(0, 0%, 86%)",
    Hold: "hsl(0, 0%, 86%)",
  };
  return colors[status];
};

export const getTagFontColor = (status) => {
  const colors = {
    "in progress": "hsl(207, 61%, 15%)",
    "In progress": "hsl(207, 61%, 15%)",
    pending: "hsl(207, 61%, 15%)",
    Pending: "hsl(207, 61%, 15%)",
    submitted: "hsl(207, 61%, 95%)",
    Submitted: "hsl(207, 61%, 95%)",
    modified: "hsl(55, 80%, 20%)",
    Modified: "hsl(55, 80%, 20%)",
    "changes requested": "hsl(55, 65%, 20%)",
    "Changes requested": "hsl(55, 65%, 20%)",
    approved: "hsl(153, 53%, 97%)",
    Approved: "hsl(153, 53%, 97%)",
    active: "hsl(153, 53%, 97%)",
    Active: "hsl(153, 53%, 97%)",
    rejected: "hsl(0, 80%, 15%)",
    Rejected: "hsl(0, 80%, 15%)",
    closed: "hsl(0, 80%, 15%)",
    Closed: "hsl(0, 80%, 15%)",
    terminated: "hsl(0, 80%, 15%)",
    Terminated: "hsl(0, 80%, 15%)",
    trails: "hsl(153, 53%, 15%)",
    Trails: "hsl(153, 53%, 15%)",
    hold: "hsl(0, 0%, 20%)",
    Hold: "hsl(0, 0%, 20%)",
  };
  return colors[status];
};

export const BACKGROUND_HIGHLIGHT_COLOR = "hsl(153deg 53% 53% / 0.3)";

export const logDev = (...args) => {
  if (process.env.NODE_ENV === "development") {
    console.log(...args);
  }
};

export const getObjectInArray = ({ array, key, value }) => {
  for (const element of array) {
    if (element[key] === value) {
      return element;
    }
  }
};

// Input: two objects representing previous and current form answers
// Output: true if answers are identical, false if there is a difference
// NOTE: the values being compared must be primitive data types
// Was going to be used in FormFields.js at const formStatusToWrite = ... to add logic to use previous form status
// for submission if user made no changes and clicked submit again (currently will update status to "modified")
export const areFormAnswersIdentical = (prevAnswers, currentAnswers) => {
  return Object.keys(prevAnswers).every((key) => prevAnswers[key] === currentAnswers[key]);
}
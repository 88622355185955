import React, { useContext, useState, useEffect, createContext } from "react";
import { logDev } from "../helpers/globalHelpers";
import { supabase } from "../helpers/supabase";

// create a context for authentication
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // create state values for user data and loading
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [dba, setDba] = useState();
  const [mfa, setMfa] = useState(false);
  const [userType, setUserType] = useState();
  const [admin, setAdmin] = useState();
  const [adminViewOnlyFlag, setAdminViewOnlyFlag] = useState();

  const setAuthVars = (session) => {
    // If there is a user, get their email and
    // check if they are associated with a DBA
    if (session?.user) {
      const email = session.user.email;
      supabase
        .rpc("join_users_admins")
        .select("email, dba, admin_flag, admin_view_only_flag, user_type")
        .eq("email", email.toLowerCase())
        .then((response) => {
          if (response.body?.length > 0) {
            setDba(response.body[0].dba);
            setAdmin(response.body[0].admin_flag);
            setUserType(response.body[0].user_type);
            setAdminViewOnlyFlag(response.body[0].admin_view_only_flag);
          }
          setUser(session.user);
          setLoading(false);
        });
    }
    // If no user, set loading to false to move forward
    else {
      setUser(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    // get session data if there is an active session
    const session = supabase.auth.session();
    logDev("session:", session);

    setAuthVars(session);

    // listen for changes to auth
    const { data: listener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        logDev("eventListener:", event);
        logDev("sessionListener:", session);
        setAuthVars(session);
      }
    );

    // cleanup the useEffect hook
    return () => {
      listener?.unsubscribe();
    };
  }, []);

  const value = {
    signUp: (data) => supabase.auth.signUp(data),
    signIn: (data) => supabase.auth.signIn(data),
    signOut: () => supabase.auth.signOut(),
    user,
    dba,
    userType,
    admin,
    adminViewOnlyFlag,
    mfa,
    setMfa,
  };

  // use a provider to pass down the value
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// export the useAuth hook
export const useAuth = () => {
  return useContext(AuthContext);
};

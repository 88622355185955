import { navigate } from "gatsby";
import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { FaEnvelope, FaKey } from "react-icons/fa";
import { RingLoader } from "react-spinners";

import { useAuth, AuthProvider } from "../context/auth";

import {
  loginBoxStyles,
  loginContainerStyles,
  loginFieldStyles,
} from "../styles/styles";

import "../styles/styles.scss";

const Login = () => {
  const [loginOrSignup, setLoginOrSignup] = useState("login");
  const [authError, setAuthError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const { signIn, signUp } = useAuth();

  const changeLoginSignup = () => {
    reset();
    if (loginOrSignup === "login") {
      setLoginOrSignup("signup");
    } else if (loginOrSignup === "signup") {
      setLoginOrSignup("login");
    }
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    if (loginOrSignup === "login") {
      const { error } = await signIn({
        email: data.email,
        password: data.password,
      });
      setSubmitting(false);
      if (error) {
        alert(error.message);
      } else {
        navigate("/confirmCode");
      }
    } else if (loginOrSignup === "signup") {
      if (data.password !== data.confirm_password) {
        alert("Passwords must match");
        return;
      }
      const { error } = await signUp({
        email: data.email,
        password: data.password,
      });
      setSubmitting(false);
      if (error) {
        alert(error.message);
      } else {
        navigate("/confirmCode");
      }
    }
  };

  return (
    <div class="section" style={loginContainerStyles}>
      <div class="columns is-centered">
        <div class="column is-4-desktop is-6-tablet">
          <form
            // class="box"
            className="fpLoginBox"
            style={loginBoxStyles}
            onSubmit={handleSubmit(onSubmit)}
          >
            <p class="title has-text-centered">
              {loginOrSignup === "signup" ? "Sign up" : "Login"}
            </p>
            {/* Email input */}
            <div class="field" style={loginFieldStyles}>
              <label class="label">Email</label>
              <div class="control has-icons-left">
                <input
                  class="input"
                  type="email"
                  {...register("email", { required: true })}
                />
                <span class="icon is-small is-left">
                  <FaEnvelope />
                </span>
              </div>
              {errors.email?.type === "required" && (
                <p class="has-text-danger is-size-7">Email is required</p>
              )}
            </div>
            {/* Password input */}
            <div class="field" style={loginFieldStyles}>
              <label class="label">Password</label>
              <div class="control has-icons-left">
                <input
                  id="password-input"
                  class="input"
                  type="password"
                  {...register("password", {
                    required: true,
                    minLength: 8,
                  })}
                />
                <span class="icon is-small is-left">
                  <FaKey />
                </span>
              </div>
              {errors.password?.type === "minLength" &&
                loginOrSignup === "signup" && (
                  <p class="has-text-danger is-size-7">
                    Password must be 8 characters
                  </p>
                )}
              {errors.password?.type === "required" &&
                loginOrSignup === "signup" && (
                  <p class="has-text-danger is-size-7">Password is required</p>
                )}
            </div>

            {/* Confirm password (for signup only) */}
            {loginOrSignup === "signup" && (
              <div class="field" style={loginFieldStyles}>
                <label class="label">Confirm Password</label>
                <div class="control has-icons-left">
                  <input
                    id="password-confirm-input"
                    class="input"
                    type="password"
                    {...register("confirm_password", {
                      required: true,
                      minLength: 8,
                    })}
                  />
                  <span class="icon is-small is-left">
                    <FaKey />
                  </span>
                </div>
                {errors.confirm_password?.type === "minLength" && (
                  <p class="has-text-danger is-size-7">
                    Password must be 8 characters
                  </p>
                )}
                {errors.confirm_password?.type === "required" && (
                  <p class="has-text-danger is-size-7">
                    Please confirm your password
                  </p>
                )}
              </div>
            )}
            <div className="fpMobileFlexColumn" class="field columns is-mobile">
              {/* Show password */}
              <div class="column">
                <label>
                  <input
                    type="checkbox"
                    onClick={() => {
                      // Switch show password
                      const passwordInput =
                        document.getElementById("password-input");
                      if (passwordInput.type === "password") {
                        passwordInput.type = "text";
                      } else {
                        passwordInput.type = "password";
                      }
                      if (loginOrSignup === "signup") {
                        const passwordConfirmInput = document.getElementById(
                          "password-confirm-input"
                        );
                        if (passwordConfirmInput.type === "password") {
                          passwordConfirmInput.type = "text";
                        } else {
                          passwordConfirmInput.type = "password";
                        }
                      }
                    }}
                  />
                  <span style={{ marginLeft: "5px" }}>Show password</span>
                </label>
              </div>
              {/* Forgot password link (only on login) */}
              {loginOrSignup === "login" ? (
                <div class="column">
                  <a
                    onClick={() => {
                      navigate("/forgotPassword");
                    }}
                  >
                    Forgot password?
                  </a>
                </div>
              ) : (
                <div />
              )}
            </div>
            {/* Login/sign up button */}
            <div class="field" style={loginFieldStyles}>
              <button class="button is-fullwidth fpLoginButton" type="submit">
                {submitting ? (
                  <RingLoader size={30} />
                ) : loginOrSignup === "login" ? (
                  "Login"
                ) : (
                  "Sign up"
                )}
              </button>
            </div>
            {/* Switch between login and sign up */}
            <div class="field">
              {loginOrSignup === "login" ? (
                <p class="has-text-centered">
                  Don't have an account? <br />
                  <a onClick={changeLoginSignup}>Sign up</a>
                </p>
              ) : (
                <p class="has-text-centered">
                  Already have an account? <br />
                  <a onClick={changeLoginSignup}>Login</a>
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const LoginPage = () => {
  return (
    <AuthProvider>
      <Login />
    </AuthProvider>
  );
};

export default LoginPage;
